<template>
  <div>
    SysLog
  </div>
</template>

<script>
export default {
  name: "SysLog"
}
</script>

<style scoped>

</style>